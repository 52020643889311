import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, useParams } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import JsxParser from 'react-jsx-parser';
import { Theme, makeStyles } from '@material-ui/core/styles';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import DragDrop from 'editorjs-drag-drop';
import I18n from 'i18n-js';
import CustomDialog from '../components/custom/CustomDialog';
import CustomExpansionPanel from '../components/custom/CustomExpansionPanel';
import NotFound from '../components/NotFound';
import SelfTestQuestion from '../components/selfTestQuiz/SelfTestQuiz';
import SessionFooter from '../components/session/SessionFooter';
import { MODULE_SESSION_QUIZ, SESSION_QUIZ } from '../config/routes';
import CourseManager from '../services/CourseManager';
import generateUrl from '../services/generateUrl';
import { Store } from '../types';
import { ContentParams } from '../services/RouteResolver';
import { BLOCK_SESSION_CONTENT } from '../config/constants';
import { blockTools, createParagraphBlockData } from '../components/session/block/utils';
import { sendEventToAnalyticsEngine } from '../redux/actions/actionCreators';
import { ActivityLabel } from '../services/analytics/userActivityClient';
import WarningIcon from '../components/icons/WarningIcon';
import CheckForUpdatesButton from '../components/updates/CheckForUpdatesButton';

const useStyles = makeStyles((theme: Theme) => ({
	smallAvatar: {
		width: 26,
		height: 26,
		fontSize: 14,
		backgroundColor: theme.palette.primary.main
	},
	sessionInfo: {
		borderBottom: '1px solid #eee'
	},
	sessionIndexWrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		borderRight: '1px solid #eee'
	},
	sessionTitle: {
		padding: '15px 20px'
	}
}));

const jsxComponents = {
	Button,
	Card,
	CardHeader,
	CardContent,
	CardActionArea,
	CardActions,
	CardMedia,
	Paper,
	ExpansionPanel: CustomExpansionPanel,
	Accordion: CustomExpansionPanel,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	ExpandMore,
	Chip,
	Divider,
	CircularProgress,
	LinearProgress,
	Typography,
	SelfTestQuestion,
	CustomDialog
};

interface Params extends Pick<ContentParams, 'moduleHashID' | 'sessionHashID' | 'courseHashID'> {}

interface Props extends RouteComponentProps<Params> {}

const SessionDetailScreen = (props: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { moduleHashID, sessionHashID, courseHashID } = useParams<Params>();
	const [editor, setEditor] = useState<EditorJS | null>(null);
	const readingStartTime = new Date();
	const previousContentRef = useRef<string | null>(null);

	const session = useSelector((state: Store) =>
		CourseManager.getSession(state.content.sessions, sessionHashID)
	);
	const locale = useSelector((state: Store) => state.settings.locale);
	const token = useSelector((state: Store) => state.currentUser.token);
	const color = useSelector((state: Store) => state.currentUser.institution.color);
	const sessionContentIsFetching = useSelector((state: Store) => state.content.spool.isFetching);

	useEffect(() => {
		if (session?.contentType === BLOCK_SESSION_CONTENT && !editor) {
			initBlockEditor();
		}
		if (session) {
			dispatch(
				sendEventToAnalyticsEngine({
					label: ActivityLabel.SESSION_VIEWED,
					description: `${token} viewed session ${session.hashID}`
				})
			);
		}
	}, []);

	useEffect(() => {
		const { content: currentContent, contentType } = session;
		const previousContent = previousContentRef.current;

		if (
			previousContent != null &&
			previousContent != currentContent && contentType === BLOCK_SESSION_CONTENT
		) {
			initBlockEditor();
		}

		previousContentRef.current = currentContent;
	}, [session?.content]);

	const initBlockEditor = () => {
		setEditor(null);
		if (editor && 'destroy' in editor) {
			editor.destroy();
		}

		const { content } = session;
		let data: OutputData;

		try {
			const savedContent = JSON.parse(content || '[]');
			if (Array.isArray(savedContent)) {
				data = {
					blocks: savedContent
				};
			} else {
				data = { ...savedContent };
			}
		} catch (e) {
			data =
				typeof content === 'string'
					? createParagraphBlockData(content)
					: {
							blocks: []
					  };
		}

		const newEditor = new EditorJS({
			readOnly: true,
			tools: blockTools,
			data
		});
		newEditor.isReady.then(() => {
			new DragDrop(newEditor);
			setEditor(newEditor);
		});
	};

	const renderContent = () => {
		return { __html: session?.content ?? '' };
	};

	const block = () => {
		return <div style={{ paddingTop: '13px' }} id={'editorjs'} />;
	};

	const renderer = () => {
		const content = session?.content
			? session.content
					.replace(new RegExp('<ExpansionPanel', 'g'), '<Accordion')
					.replace(new RegExp('</ExpansionPanel', 'g'), '</Accordion')
			: null;

		const jsxRegEx = /(?:<([A-Z][\w]+)\s?[\w\s={}:"]*>(.|\r|\n)*?<\/\1>|<[A-Z]([^>])+\/>)/g;
		const isJSX = content && jsxRegEx.test(content);

		if (isJSX) {
			return <JsxParser jsx={content} components={jsxComponents} />;
		}

		return <div dangerouslySetInnerHTML={renderContent()} />;
	};

	const showDownloadOngoingPlaceholder = () => {
		return (
			<Grid container justifyContent={'center'} alignItems={'center'}>
				<Grid item xs={12} style={{ textAlign: 'center', paddingTop: 20 }}>
					<CircularProgress />
				</Grid>
				<Grid item style={{ textAlign: 'center' }}>
					<p style={{ color: 'grey' }}>
						{I18n.t('update.downloadInProgress', { locale })}.<br />
						{I18n.t('update.contentWillAppear', { locale })}.
					</p>
				</Grid>
			</Grid>
		);
	};

	const showErrorWhileUpdatingPlaceholder = () => {
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} style={{ paddingBottom: 25 }}>
				<Grid item xs={12} style={{ textAlign: 'center', paddingTop: 20 }}>
					<WarningIcon style={{ color, width: 70, height: 70 }} />
				</Grid>
				<Grid item style={{ textAlign: 'center' }}>
					<p style={{ color: 'grey' }}>
						{I18n.t('update.errorWhileUpdating', { locale })}.<br />
						{I18n.t('update.connectAndRetry', { locale })}.
					</p>
				</Grid>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<CheckForUpdatesButton customText={I18n.t('update.retry', { locale })} />
				</Grid>
			</Grid>
		);
	};

	const displayContent = () => {
		if (sessionContentIsFetching && !session?.spoolComplete) {
			return showDownloadOngoingPlaceholder();
		}

		if (!session?.spoolComplete) {
			return showErrorWhileUpdatingPlaceholder();
		}

		return session?.contentType === BLOCK_SESSION_CONTENT ? block() : renderer();
	};

	if (session !== undefined) {
		if (session.content && session.content.includes('&lt;quiz&gt;') && session.quizHashID) {
			const quizUrl = generateUrl(moduleHashID ? MODULE_SESSION_QUIZ : SESSION_QUIZ, {
				':moduleHashID': moduleHashID,
				':courseHashID': session.courseHashID,
				':sessionHashID': session.hashID,
				':quizHashID': session.quizHashID
			});

			return <Redirect to={quizUrl} />;
		}

		return (
			<div className="content-layout">
				<Grid container className={classes.sessionInfo}>
					<Grid item xs={2} className={classes.sessionIndexWrapper}>
						<Avatar className={classes.smallAvatar}>{session.index + 1}</Avatar>
					</Grid>
					<Grid item xs={10}>
						<Typography variant="subtitle2" className={classes.sessionTitle}>
							{session.title}
						</Typography>
					</Grid>
				</Grid>
				<div className="content">
					<div className="session-content">{displayContent()}</div>
				</div>

				<SessionFooter
					courseHashID={courseHashID}
					session={session}
					readingStartTime={readingStartTime}
				/>
			</div>
		);
	}

	return <NotFound {...props} />;
};

export default SessionDetailScreen;
