import CourseManager from '../CourseManager';
import store from '../../redux/store/store';

interface FetchedProgression {
	sessionHashID: string;
	createdAt: any;
}

const getViewedSessions = (progressions: FetchedProgression[]): string[] => {
	const {
		content: {
			viewed: { sessions: viewedSessions }
		}
	} = store.getState();

	let sessions: string[] = viewedSessions ? [...viewedSessions] : [];

	progressions.forEach((progression: FetchedProgression) => {
		if (sessions && !sessions.includes(progression.sessionHashID)) {
			sessions.push(progression.sessionHashID);
		}
	});

	return sessions;
};

const getViewedFolders = (progressions: FetchedProgression[]): string[] => {
	const {
		content: {
			folders: allFolders,
			sessions: allSessions,
			viewed: { folders: viewedFolders }
		}
	} = store.getState();

	let folders: string[] = viewedFolders ? [...viewedFolders] : [];
	let viewedSessions = getViewedSessions(progressions);

	let folderHashIDs = Object.keys(allFolders);

	folderHashIDs.forEach((folderHashID) => {
		let folderSessions = CourseManager.getSessionsFromFolder(allSessions, folderHashID);
		let sessionHashIDs = Object.keys(folderSessions);
		let allFolderSessionsRead = true;

		sessionHashIDs.forEach((session) => {
			if (!viewedSessions.includes(session)) {
				allFolderSessionsRead = false;
			}
		});

		if (allFolderSessionsRead) {
			if (!viewedFolders.includes(folderHashID)) {
				folders.push(folderHashID);
			}
		}
	});

	return folders;
};

export const formatProgressions = (progressions: Array<FetchedProgression>) => {
	let sessions: string[] = getViewedSessions(progressions);
	let folders: string[] = getViewedFolders(progressions);

	return {
		sessions,
		folders
	};
};

export const isAllCourseSessionsViewed = (courseHashID: string) => {
	const {
		content: { sessions }
	} = store.getState();

	let courseSessions: string[] = Object.keys(sessions).filter(
		(sessionKey) => sessions[sessionKey].courseHashID === courseHashID
	);

	const {
		content: {
			viewed: { sessions: viewedSessions }
		}
	} = store.getState();

	let viewedCourseSessions: string[] = viewedSessions.filter((session: string) =>
		courseSessions.includes(session)
	);

	return viewedCourseSessions.length === courseSessions.length;
};
