export default ({
	course: courseName,
	module: moduleName
}: {
	course: any;
	session: any;
	folder: any;
	module: any;
}) => ({
	course: {
		noContentAvailable: `Nta nyandiko irimo`,
		view: `Reba`,
		sessionsCompleted: 'Warangije neza amasomo yose mu masomo ya %{courseName}',
		congratulations: 'Turagushimiye',
		great: 'Ni byiza cyane'
	},
	session: {
		nextButton: `Ibikurikiraho`,
		previous: `Ibibanziriza ibi ngibi`,
		quizButton: `Tangira ukore akitozo`,
		surveyButton: `Tangira usubize ibibazo by'ubushakashatsi`,
		end: `Kora`
	},
	question: {
		next: `Ibikurikiraho`,
		previous: `Ibibanziriza ibi ngibi`,
		saveAndNext: `Bika ukomeze`,
		answer: `Subiza`,
		checkAnswer: `Genzura igisubizo`,
		numericalRangeFeedback: `Igisubizo cy'ukuri ni umubare uri hagati ya <b>%{lowerNumber}</b> na <b>%{higherNumber}</b>`,
		answerAll: `Turagusaba gusubiza ibibazo byose <b> bisabwa </b> mbere yo gukomeza`,
		maxFiles: 'Ntushobora gukora gusubira %{number} iziganiro',
		uploadAll: 'Mwongere mufatire ibihumbi <b>bisobanuro</b> byose ugereranije.',
		confirmRemove: 'Uraga ko ushaka kugira gutyo ifayiro',
		confirm: 'Emeza',
		backToStart: `Subira inyuma kugira ngo utangire`,
		attempt: `Gerageza gusubiza ikibazo mbere yo gukomeza`,
		question: 'Ikibazo',
		of: `cya`,
		end: `Soza`,
		hint: `Kanda kugira ngo ujye ku bindi`,
		helperText: {
			essay: `Injizamo igisubizo gikurikira`,
			multipleChoice: `Hitamo igisubizo kimwe`,
			compoundMultipleChoice: `Hitamo ibisubizo byose bikwiye`,
			numericalRange: `Injizamo umubare uri mu bisubizo bikwiye`,
			shortExact: `Injizamo igisubizo gikurikira`,
			sequence: `Kurura ibintu ubitondeke mu buryo bukwiye`,
			ratingScale: `Nyereza uhitemo igisubizo`
		}
	},
	feedback: {
		good: `Wabikoze neza!`,
		bad: `Nta bwo ari byo`
	},
	tokenError: {
		'401': `Inzira yawe ifite ikibazo. Bimenyeshe abantu bo muri Chalkboard Education bagufashe`,
		'402': `Tokeni yawe irimo gukoreshwa ahandi. Ikigo cyawe ntabwo kikwemerera kwiyandikisha ahantu henshi hatandukanye. Niba utekereza ko habayeho kwibeshya, iyambaze abantu bo muri Chalkboard Education`
	},
	discussion: {
		title: 'Ikiganiro',
		messagePlaceholder: 'Injiza ubutumwa bwawe'
	},
	update: {
		label: `Fungura`,
		checkForUpdates: `Genzura niba hari ibishya bihari`,
		needUpdate: `Hari ibintu bishya…`,
		updating: `Gushakisha inyandiko runaka`,
		checking: `Gusuzuma inyandiko…`,
		fetchingQuizResults: 'Kubika ibyavuye mu bizamini...',
		downloadSize: `{{size}} {{type}}`,
		toDownload: ` gukuramo`,
		KB: `Kilobayiti`,
		MB: `Megabayiti`,
		upToDate: `Inyandiko zose zamaze gufungurwa!`,
		errorWhileCheckingUpdates: `Habayeho ikibazo k'ihuzanzira igihe wageragezaga kugenzura niba hari ibishya bihari.`,
		errorWhileUpdating: `Habayeho ikibazo k'ihuzanzira igihe wageragezaga gufungura inyandiko.`,
		stayOnline: `Guma ku murongo kandi ipaji igume ifunguye.Ibi bishobora gufata iminota myinshi.`,
		offline: `Wavuye ku murongo`,
		success: `Inyandiko yafungutse neza!`,
		warning: `Inyandiko yafungutse igice`,
		retry: `Ongera ugeregeze`,
		retryInFewMinutes: 'Nimushake kandi mwongere mufatire mu minota irindwi',
		connectAndRetry: 'Mbwiriza ko uri kuri intaneti kandi ogerageze',
		connectAndRetryInMinutes:
			'Mwongere mufatire mukora muri intaneti kandi mu minota irindwi mwongere mufatire',
		downloadInProgress: 'Gukino kuri mu rukurikirane',
		contentWillAppear: 'Uzabona konteni ingaruka irangije',
		edit: `Gira ibyo uhinduramo`,
		downloadingContent: `Igikorwa cyo gufungura inyandiko kirimo kuba`,
		missing: 'Tegeza medya zidakurikiraho',
		dismiss: `Bireke`,
		contentLoading:
			"Gukino kya konteni kiri mu rukurikirane. Waza wumva imiterere yabyo ukanda mu birongozo by'umuso w'iburyo bwawe"
	},
	send: {
		label: {
			quiz: `Ohereza amakuru y'intambwe umaze gutera wifashishije:`,
			survey: "Ibikorwa byanyu mukomeze. Igihe muriyo mukomeze wamaze kubasura iyo mukoreye 'Ohereza'"
		},
		sessionListButton: `Urutonde rw'amazina y'ibyiciro by'amasomo`,
		nextButton: `Ibikurikiraho`,
		medium: {
			internet: `Murandasi`,
			sms: `Ubutumwa bugufi`
		},
		validation: {
			success: `Urakoze. Amakuru arebana n'intambwe umaze gutera yabitswe`,
			fail: `Kwemeza izina ry'ikiciro k'isomo ntabwo byagenze neza`
		},
		sms: {
			title: `Ohereza amakuru y'intambwe umaze gutera wifashishije ubutumwa bugufi`,
			label: `Uzohereza nimero ukoresheje ubutumwa bugufi ubone nimero igaragaza intambwe umaze gutera. Ntabwo ugomba guhindura izo nimero zombi. Urakoze.`,
			button: `Ohereza wifashishije ubutumwa bugufi`,
			notworking: {
				label: `Ohereza ubutumwa bugufi wifashishije intoki`,
				button: `Ntabwo birimo gukunda?`,
				toPhone: `Nimero ya telefoni`,
				unblockCode: `Nimero ugomba kohereza`
			},
			progressCode: {
				button: `Injizamo nimero igaragaza intambwe umaze gutera`,
				placeholder: `Nimero igaragaza intambwe umaze gutera`,
				title: `Injizamo nimero wohererejwe hifashishijwe ubutumwa bugufi bukurikira:`,
				cancel: `Burizamo iki gikorwa`,
				submit: `Ohereza`
			}
		}
	},
	login: {
		checking: `Kugenzura…`,
		start: `Tangira`,
		title: `Injiramo`,
		message: `Ushobora kwinjiramo ukoresheje inzira y'ubutumwa bugufi yatanzwe. Ubundi buryo ushobora kwifashisha ni ukwinjizamo tokeni yawe ikurikira`,
		hint: `Ushobora gukoresha inzira yo kwinjiramo wohererejwe hifashishijwe ubutumwa bugufi bugufasha kongera kwinjiramo`,
		tokenPlaceholder: `Tokeni`
	},
	account: {
		progress: {
			title: `Iterambere`,
			subtitle: `%{read}/%{remaining} Amasomo yasomwe`
		},
		language: `Ururimi`,
		settings: {
			label: `Uko ibintu biteguye`
		},
		about: `Ibirebana na…`,
		consent: `Emera`,
		support: {
			title: `Imirongo yakwifashishwa mu gusaba ubufasha`,
			subtitle: `Ushobora kutwiyambaza ukoresheje ubu buryo mu gihe ukeneye ubufasha bwisumbuyeho`
		}
	},
	error: {
		label: `Yooo…Biragaragara ko hari ikintu kitagenze neza.`,
		report: `Tanga amakuru y'ibyanze gukora`,
		back: `Garuka ahabanza`,
		dismiss: `Bireke`,
		authenticationError: `Ikosa mu kugenzura umwirondoro`,
		spoolError: {
			title: `Gufunguka nta bwo byarangiye`,
			description:
				'Some files failed to download. Not to worry, we`ll attempt to redownload them on the next update.'
		}
	},
	notFound: {
		title: `404 iyi paji ntabwo ishoboye kuboneka`,
		description: `Tukwiseguyeho, ipaji urimo gushaka nta bwo ibaho`,
		back: `Subira inyuma`
	},
	logout: {
		title: `Emeza ko ushaka gusohokamo`,
		message: `Uremeza neza ko ushaka gusohokamo`,
		button: `Sohokamo`,
		cancelButton: `Burizamo iki gikorwa`
	},
	selfTestQuiz: {
		okay: `Yego`,
		checkAnswer: `Genzura igisubizo`,
		answers: `Ibisubizo`,
		essayResponse: `Urakoze kubera igisubizo utanze!`
	},
	quiz: {
		label: `Ugomba kubanza gukora aka kitozo mbere yo gukomeza!`,
		button: {
			begin: `Tangira`,
			skip: `Bisimbuke`
		},
		commence: 'Uri kugenda uheraho kizanye cyo muri iki kiganiro',
		upload: 'Hitamo ikiganza gikurikira kuburira',
		uploadedFiles: 'Ibihumbi bikurikira',
		tapToUpload: 'Gusinya ikoni kugira ngo uhitemo ibihumbi',
		submit: {
			submitting: 'Ohereza',
			stayOnline:
				'Urab sicherwe ko ushaka kohereza ibisubizo byako?\nMbwiriza ko uri kuri intaneti kandi ushishikarire ikarasi.',
			inProgress: 'Gutanga birakomeje. Ibi birashobora gufata umunota',
			success: `Ibisubizo by'akitozo byoherejwe neza!`,
			error: `Habaye ikibazo mu kohereza ibisubizo by'akitozo! Uze kongera wohereze.`,
			offline: 'Ntashobora kohereza ibisubizo uba utari kuri intaneti'
		},
		continue: `Komeza`,
		submitButton: 'Ohereza kizanye'
	},
	survey: {
		submitButton: `Ohereza ibisubizo by'ubushakashatsi`,
		submittedButton: `Ibisubizo by'ubushakahstasi byoherejwe`,
		commence: "Uri kugenda uheraho isondwa ry'iki kiganiro",
		submit: {
			success: "Isondwa yanditswe n'ubu yaje kuboneka!"
		}
	},
	badge: {
		quiz: `Akitozo`,
		survey: `Ubushakashatsi`,
		required: `Ni ngombwa`
	},
	menu: {
		modules: `${moduleName}s`,
		courses: `Amazina y'amasomo`,
		account: `Konti`,
		bookmarks: `Inzira zihuse zigufasha kwinjiramo`,
		quizReview: `Kureba ibiri mu mwitozo wakozwe`,
		help: `Ubufasha`,
		about: `Ibirebana na…`
	},
	consent: {
		title: `Nemeye ko Chalborad Education SAS ikusanya amakuru ikayabika:`,
		data: `Intambwe maze gutera n'amanota yanjye hifashishijwe ubu buryo`,
		analytics: `Amakuru arebana n'imyitwarire yanjye n'andi makuru anyerekeyeho arebana n'imikoreshereze y'ubu buryo`
	},
	custom: {
		moreInfo: `Andi makuru`,
		gotIt: `Wabigezeho`,
		searchPlaceholder: 'Tangira kwandika kugirango ushakishe',
		noSearchResults: 'Nta bisubizo byabonetse'
	},
	help: {
		title: `Uko ubu buryo bukoreshwa:`,
		menu: {
			title: `Menya urutonde rw'ibikubiyemo`,
			subtitle: `Kanda ku kamenyetso kugira ngo ufungure urutonde rw'ibikubiyemo. Urutonde rw'ibikubiyemo rurimo inzira ikugeza ku mazina y'amasomo no kuri konti yawe`
		},
		courses: {
			title: `Amazina y'amasomo`,
			subtitle: `On the ${courseName}s page, click on 'Reba' to open a ${courseName}.`
		},
		folders: {
			title: `Amazina y'ububiko`,
			subtitle: `Buri zina ry'isomo rigabanyijemo amazina y'ububiko`,
			description: `Buri zina ry'ububiko rigabanyijemo amazina y'ibyiciro by'amasomo. Amazina y'ibyiciro by'amasomo akubiyemo ibyo umuntu ukoresha ubu buryo ashobora kwiga.`
		},
		sessions: {
			title: `Amazina y'ibyiciro by'amasomo`,
			subtitle: `Ibyiciro by'amasomo bikubiyemo ibintu umuntu ukoresha ubu buryo ashobora kwiga`
		},
		navigatingSessions: {
			title: `Kwinjira mu mazina y'ibyiciro by'amasomo`,
			back: {
				subtitle: `Mu gihe uri mu izina ry'ikiciro k'isomo, ushobora kugaruka inyuma ku rutonde rw'ibanze wifashishije umwambi wo kugaruka inyuma…`
			},
			next: {
				subtitle: `Cyangwa ukajya ku izina ry'ikiciro k'isomo gikurikiraho wifashishije buto iri ku ndiba y'ipaji`
			}
		},
		bookmarks: {
			title: `Inzira zihuse zigufasha kwinjiramo`,
			subtitle: `Ifashishe akamenyetso k'inzira yihuse igufasha kwinjira mu mapaji runaka mu rwego rwo kubika amapaji kugira ngo uzajye ushobora kuyinjiramo mu buryo bwihuse wifashishije igice k'Inzira zihuse  kiri ku rutonde rw'ibikubiyemo.`
		},
		quizzes: {
			title: `Utwitozo/Amasuzumabumenyi`,
			subtitle: `Ushobora gusanga akitozo ku ntangiriro y'izina ry'isomo cyangwa ku ntangiriro y'ububiko bw'isomo. Cyangwa ku musozo w'ikiciro k'isomo.`,
			description: `Ikitonderwa: Ibyiciro by'amasomo bifite akitozo bigaragazwa n'akamenyetso:`
		},
		addToHomescreen: {
			title: `Bishyire ku irembo rya mudasobwa/telefoni yawe`,
			subtitle:
				'You can install this application on your homescreen for quick and easy access when you`re on the go.'
		},
		account: {
			title: `Konti`,
			subtitle: `Ku ipaji ya konti, ushobora:`,
			updates: {
				description: `Kugenzura niba hari amasomo mashya ahari`
			},
			language: {
				description: `Guhindura ururimi rukoreshwa`
			},
			logout: {
				description: `Sohoka muri konti yawe`
			},
			contact: {
				description: `Tumenyeshe mu gihe uhuye n'ibibazo mu mikoreshereze y'ubu buryo.`
			}
		},
		tutorial: `Ongera utangire gukoresha ubu buryo bugufasha kwiga.`
	},
	onboarding: {
		'0': {
			title: `Amasomo wiga igihe uboneye umwanya`,
			subtitle: `Imfashanyigisho zawe zose ziri ahantu hamwe…harimo ndetse utwitozo tugamije gusuzuma ubumenyi bwawe.`
		},
		'1': {
			title: `Ushobora kwiga udafite murandasi!`,
			subtitle: `Nyuma y'uko ufungura amasomo runaka, ushobora kuyageraho igihe icyo ari cyo cyose n'igihe waba udafite murandasi.`
		},
		'2': {
			title: `Iga igihe icyo ari cyo cyose, aho uri hose!`,
			subtitle: `Gera ku masomo yawe wifashishije igikoresho icyo ari cyo cyose k'ikoranabuhanga. Ifashishe inzira y'ubutumwa bugufi yatanzwe.`
		},
		'3': {
			title: `Injira mu kiganiro`,
			subtitle: `Gira uruhare mu kwigana na bagenzi bawe mu byiciro by'amasomo bigenewe kuganira.`
		},
		'4': {
			title: `Shyira ubu buryo ku irembo rya mudasobwa/telefoni yawe!`,
			subtitle:
				"Install this application on your homescreen for quick and easy access when you're on the go.",
			instructions: {
				menu: `Kora kuri  <strong>buto y'urutonde rw'ibikubiyemo</strong>hanyuma uhitemo <strong> Shyira ubu buryo ku irembo rya mudasobwa/telefoni</strong>`,
				share: `Kora kuri  <strong>buto yo gusakaza</strong>hanyuma uhitemo <strong> Shyira ubu buryo ku irembo rya mudasobwa/telefoni</strong>`,
				clickButton: `…cyangwa ukande kuri buto ikurikira`
			}
		},
		addToHomescreen: `Bishyire ku irembo rya mudasobwa/telefoni yawe`,
		getStarted: `Tangira`,
		next: `Ibikurikiraho`,
		skip: `Bisimbuke`
	},
	'guided-tour': {
		title: `Uburyo bugufasha kwiga`,
		button: {
			finish: `Soza`,
			next: `Ibikurikiraho`,
			previous: `Ibibanziriza ibi ngibi`,
			're-run': `Ongera utangire gukoresha ubu buryo bugufasha kwiga.`
		},
		modules: {
			'1': {
				title: `${moduleName}s Page`,
				content: `Welcome to the <b>${moduleName}s</b> page. <b>${moduleName}s</b> are groups of <b>${courseName}s</b> that you have been assigned to`
			},
			'2': {
				content: `Clicking on a <b>${moduleName}</b> takes you to a list of all the <b>${courseName}s</b> under the <b>${moduleName}</b>`
			}
		},
		'module-courses': {
			'1': {
				content: `You're on the <b>${courseName}s</b> page of the <b>${moduleName}</b> you just selected. The ${courseName}s under the <b>${moduleName}</b> are listed here`
			}
		},
		courses: {
			'1': {
				title: `Ipaji iriho amazina y'amasomo`,
				content: `Wageze ku <b>ipaji y'amazina y'amasomo </b> . Urutonde <b> rw'amazina yose y'amasomo </b> ruri hano.`
			},
			'2': {
				content: `Igihe cyose ubonye ibi ngibi, biba bisobanuye ko ko hari ibintu bishya ushobora gufungura.`
			},
			'3': {
				content: `Gukanda kuri<b>Fungura </b> bisobanuye ko uba ubitse inyandiko nshya.`
			},
			'4': {
				content: `Gukanda ku gishushanyo bikugeza ku nshamake <b> y'izina ry'isomo </b>`
			},
			'5': {
				content: `Gukanda ku <b>izina ry'isomo</b>bikugeza ku ipaji iriho amakuru arebana n'<b> iryo zina ry'isomo</b>`
			},
			'6': {
				content: `Kanda kuri <b>Genzura niba hari ibintu bishya bihari</b>urebe niba hari inyandiko nshya zashyizweho`
			}
		},
		'course-info': {
			'1': {
				content: `Iyi ni <b>amazina y'amasomo </b> ipaji. Urasangaho amakuru y'inyongera arebana n'izina ry'isomo, <b> amazina y'ibyiciro by'amasomo</b>ahurijwe mu <b> mazina y'ububiko</b>`
			},
			'2': {
				content: `Ushobora gusanga hano inshamake y'<b>izina ry'isomo</b>`
			},
			'3': {
				content: `Munsi y'inshamake y'izina ry'isomo hari urutonde rw'<b>amazina y'ububiko</b>`
			},
			'4': {
				content: `Gukanda ku <b>izina ry'ububiko</b>bikwereka urutonde rw'<b>amazina y'ibyiciro by'amasomo</b> biri muri ubwo bubiko`
			},
			'5': {
				content: `Uru ni urutonde rw'<b>amazina y'ibyiciro by'amasomo</b>`
			},
			'6': {
				content: `Kugira ngo utangire gusoma, kanda ku<b>izina ry'ikiciro k'isomo</b>`
			}
		},
		'session-details': {
			'1': {
				content: `Ikaze ku kiciro cya mbere k'<b>izina ry'ikiciro k'isomo</b>. Aha ni ho uzajya umara umwanya wawe munini.`
			},
			'2': {
				content: `Kugira ngo bikorohere kugaruka ku <b>izina ry'ikiciro k'isomo</b>, kanda ku <b> kamenyetso k'inzira yihuse igufasha kwinjiramo</b>bihite biguha uburyo bwihuse bwo kugaruka kuri iryo <b> zina ry'ikiciro k'isomo </b>. <b> Inzira zihuse </b> ushyiraho ushobora kuzisanga ku <b> Ipaji y'inzira zihuse</b>`
			},
			'3': {
				content: `Gukanda ku mwambi wo hejuru bigufasha kugaruka ku ipaji ibanziriza iyo uriho.`
			},
			'4': {
				content: `Kanda ku <b> kamenyetso k'ikiganiro</b> kugira ngo ubone <b> ipaji y'ikiganiro </b> y'<b>izina ry'ikiciro k'isomo</b> urimo kureba.`
			},
			'5': {
				content: `Kugira ngo ujye ku <b>izina ry'ikiciro k'isomo</b> gikurikiraho mu <b>izina ry'ububiko</b>, kanda kuri <b>buto y'ibikurikiraho</b>`
			},
			'6': {
				content: `Kugira ngo ugaruke ku <b> izina ry'ikiciro k'isomo </b> kibanziriza icyo uriho, kanda kuri <b> buto y'ibibanziriza ibi ngibi</b>`
			},
			'7': {
				content: `Iyi ni <b> buto y'urutonde rw'ibikubiyemo</b>. Kuyikandaho ku ipaji iyo ari yo yose bikugeza <b>ahantu hari urutonde rw'ibikubiyemo</b>`
			}
		},
		bookmarks: {
			'1': {
				content: `Amapaji yose washyiriyeho inzira zihuse zigufasha kuyinjiramo agaragara hano!`
			},
			'2': {
				content: `Gukanda ku <b>nzira yihuse igufasha kwinjiramo</b> ikujyana ku ipaji washyiriyeho iyo nzira mu buryo bwihuse`
			}
		},

		help: {
			'1': {
				content: `Ikaze ku <b>ipaji y'ubufasha</b>. Jya usura iyi paji igihe cyose uzajya uhura n'ikibazo kirebana no gusobanukirwa imikoreshereze y'ubu buryo.`
			},
			'2': {
				content: `Ushobora gusura <b>ipaji y'ubufasha</b>igihe icyo ari cyo cyose ushaka kwiyambaza iyi nyandiko yo kwifashisha…`
			},
			'3': {
				content: `Kanda ku ngingo iyo ari yo yose iri ku rutonde kugira ngo ubone amakuru yisumbuyeho arebana na yo…`
			},
			'4': {
				content: `Ushobora kandi kwandikira Chalkboard Education wifashishije imeyili`
			},
			'5': {
				content: `Cyangwa utuvugishe wifashishije nimero yacu ya telefoni yihuse…`
			}
		},

		quiz: {
			'1': `Ikaze ku <b>ipaji y'akitozo</b>. Uzajya ubona ipaji isa n'iyi ngiyi igihe cyose uzaba ugiye gukora akitozo.`,
			'2': `Ibi ni ibisobanuro birebana n'akitozo. Kanda <b>buto yo Gutangira</b>kugira ngo utangire.`
		},
		quizQuestion: {
			'1': `Ibi bikwereka ikibazo uriho n'umubare w'ibibazo wasize…`,
			'2': `Sobanukirwa ikibazo hanyuma ugisubize. Kanda <b>buto y'ibikurikiraho</b>iri ku ndiba y'ipaji kugira ngo utangire ikibazo gikurikiraho.`,
			'3': `Ibisubizo byawe bihita byibika nyuma yo kubyinjizamo. Ntugashidikanye kubigarukaho kugeza igihe uzoherereza akitozo, niba bishoboka!`
		}
	},
	routes: {
		login: `Injiramo`,
		welcome: `Ikaze`,
		modules: `${moduleName}s`,
		courses: `Amazina y'amasomo`,
		help: `Ubufasha`,
		about: `Ibirebana na…`,
		bookmarks: `Inzira zihuse zigufasha kwinjiramo`,
		quizReview: `Kureba ibiri mu mwitozo wakozwe`,
		account: `Konti`,
		folders: `Amazina y'ububiko`,
		sessions: `Amazina y'ibyiciro by'amasomo`,
		quiz: `Akitozo`,
		question: `Ikibazo`
	},
	bookmarks: {
		none: `Biragaragara ko nta nzira zihuse wabitse zigufasha kwinjiramo…`,
		added: `Inzira yihuse igufasha kwinjiramo yashyizweho`,
		removed: `Inzira yihuse igufasha kwinjiramo yakuweho`
	},
	courses: {
		none: `Amazina y'amasomo yafungutse araza kugaragara hano…`,
		noPurchases: `Nta masomo uragura kugeza ubu`,
		purchaseMore: `Kanda hano ushyiremo amazina y'amasomo`,
		purchaseCourses: `Gura amasomo`
	},
	quizReview: {
		questionsTitle: {
			survey: `Ibibazo & ibisubizo`,
			graded: `Byanze gukora`
		},
		none: `Nta bwo urakora <b>utwitozo tugomba gukosorwa</b>cyangwa <b>ibibazo by'ubushakashatsi</b>`,
		correct: 'Bikosore',
		wrong: 'Atari byo',
		notMarked: 'Ntabwo byashyizweho ikimenyetso',
		resultsIsFetching: 'Kubika ibizamini byakozwe mbere kugira ngo bisuzumwe...',
		errorFetchingResults:
			'Mwihangane, habaye ikosa mukuramo ibyavuye mu bizamini. Nyamuneka reba ko hariho ibishya kugira ngo wongere ugerageze.'
	},
	folder: {
		quizLabel: `Ugomba kubanza gukora akitozo mbere yo kureba ibikubiye muri ubu bubiko bwitwa gutya`,
		surveyLabel: `Ugomba kubanza gusubiza ibibazo by'ubushakashatsi mbere yo kureba ibikubiye muri ubu bubiko bwitwa gutya`
	},
	modules: {
		none: `No ${moduleName}s downloaded...`
	},
	register: {
		signUp: `Kwinjiramo`,
		button: `IYANDIKISHE`,
		registering: `IGIKORWA CYO KWIYANDIKISHA KIRIMO KUBA`,
		loginHint: `Usanzwe ufite konti? Injiramo ukoresheje konti yawe`,
		successful: `Kwiyandikisha byagenze neza.`,
		failed: `Kwiyandikisha byanze. Ongera ugerageze.`,
		label: {
			firstName: `Izina ry'idini`,
			lastName: `Izina ry'umuryango`,
			phoneNumber: `Nimero ya telefoni`,
			language: `Ururimi`
		},
		hint: {
			firstName: `Izina ry'idini ni ngombwa`,
			lastName: `Izina ry'umuryango ni ngombwa`,
			phoneNumberLength: `Nimero ya telefoni igomba kuba igizwe n'imibarwa irenze 9`,
			phoneNumberFormat: 'Phone number should start with a `+`',
			verifyHuman: `Kora igikorwa kigamije kudufasha kugenzura niba ari wowe mu by'ukuri mbere yo gukomeza`,
			language: `Ururimi ni ngombwa`
		}
	},
	searchModal: {
		cancel: 'Guhagarika',
		search: 'Shakisha',
		searchIn: 'Shakisha'
	},
	quizType: {
		survey: 'Ubushakashatsi',
		graded: 'Amanota',
		selfAssessment: 'Kwisuzuma wenyine'
	}
});
