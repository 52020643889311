import grey from '@material-ui/core/colors/grey';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Header from './components/navigation/Header';
import Logout from './components/Logout';
import NotFound from './components/NotFound';
import Tutorial from './components/tutorials/Tutorial';
import Updates from './components/updates/Updates';
import DocumentTitle from './components/utils/DocumentTitle';
import ScrollToTop from './components/utils/ScrollToTop';
import * as routes from './config/routes';
import translations from './config/translations';
import AboutScreen from './containers/AboutScreen';
import AccountScreen from './containers/AccountScreen';
import BookmarksScreen from './containers/BookmarksScreen';
import ConsentScreen from './containers/ConsentScreen';
import CourseInfoScreen from './containers/CourseInfoScreen';
import CourseScreen from './containers/CourseScreen';
import DiscussionScreen from './containers/DiscussionScreen';
import FolderScreen from './containers/FolderScreen';
import HelpScreen from './containers/HelpScreen';
import HomeScreen from './containers/HomeScreen';
import ModuleCourseScreen from './containers/ModuleCourseScreen';
import ModuleScreen from './containers/ModuleScreen';
import OnboardingScreen from './containers/OnboardingScreen';
import PrivateRoute from './containers/PrivateRoute';
import ProgressionScreen from './containers/ProgressionScreen';
import QuestionScreen from './containers/QuestionScreen';
import QuizReviewDetailScreen from './containers/QuizReviewDetailScreen';
import QuizReviewScreen from './containers/QuizReviewScreen';
import QuizScreen from './containers/QuizScreen';
import SendSMSScreen from './containers/SendSMSScreen';
import SessionDetailScreen from './containers/SessionDetailScreen';
import SessionScreen from './containers/SessionScreen';
import SubmitQuizScreen from './containers/SubmitQuizScreen';
import SurveyQuestionScreen from './containers/SurveyQuestionScreen';
import ValidateSessionByCodeScreen from './containers/ValidateSessionByCodeScreen';
import RegisterScreen from './containers/RegisterScreen';
import { Institution, Store } from './types';
import CourseCompleteDialog from './components/quiz/CourseCompleteDialog';

interface Props {
	institution: Institution;
}
class App extends Component<Props, {}> {
	componentWillMount() {
		translations(this.props.institution.id);
	}

	render() {
		return (
			<MuiThemeProvider
				theme={createMuiTheme({
					palette: {
						primary: {
							main: this.props.institution.color || '#d8497d'
						},
						secondary: {
							main: grey[900]
						},
						background: {
							default: grey[200]
						}
					}
				})}
			>
				<HashRouter>
					<CssBaseline>
						<ScrollToTop />
						<DocumentTitle />

						<CourseCompleteDialog />

						<Header />
						{/* @ts-ignore */}
						<Updates />
						<Tutorial />
						<Logout />

						<Switch>
							<Route exact path={routes.HOME} component={HomeScreen} />
							<Route exact path={routes.LOGIN} component={ConsentScreen} />

							<Route exact path={routes.REGISTER} component={RegisterScreen} />
							<PrivateRoute exact path={routes.WELCOME} component={OnboardingScreen} />
							<PrivateRoute exact path={routes.BOOKMARKS} component={BookmarksScreen} />
							<PrivateRoute exact path={routes.QUIZ_REVIEW} component={QuizReviewScreen} />
							<PrivateRoute exact path={routes.HELP} component={HelpScreen} />
							<PrivateRoute exact path={routes.ABOUT} component={AboutScreen} />
							<PrivateRoute exact path={routes.ACCOUNT} component={AccountScreen} />

							<PrivateRoute exact path={routes.MODULES} component={ModuleScreen} />
							<PrivateRoute
								exact
								path={routes.MODULE_COURSE_LIST}
								component={ModuleCourseScreen}
							/>

							<PrivateRoute exact path={routes.COURSES} component={CourseScreen} />

							<PrivateRoute exact path={routes.COURSE_INFO} component={CourseInfoScreen} />
							<PrivateRoute
								exact
								path={routes.MODULE_COURSE_INFO}
								component={CourseInfoScreen}
							/>

							<PrivateRoute exact path={routes.FOLDER_LIST} component={FolderScreen} />
							<PrivateRoute exact path={routes.SESSION_LIST} component={SessionScreen} />
							<PrivateRoute
								exact
								path={routes.SESSION_LIST_WITHOUT_FOLDER}
								component={SessionScreen}
							/>

							<PrivateRoute
								exact
								path={routes.SESSION_DETAIL}
								component={SessionDetailScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_SESSION_DETAIL}
								component={SessionDetailScreen}
							/>

							<PrivateRoute exact path={routes.SESSION_SEND} component={ProgressionScreen} />
							<PrivateRoute
								exact
								path={routes.MODULE_SESSION_SEND}
								component={ProgressionScreen}
							/>

							<PrivateRoute exact path={routes.SESSION_SEND_SMS} component={SendSMSScreen} />
							<PrivateRoute
								exact
								path={routes.MODULE_SESSION_SEND_SMS}
								component={SendSMSScreen}
							/>

							<PrivateRoute
								exact
								path={routes.SESSION_VALIDATE_SMS}
								component={ValidateSessionByCodeScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_SESSION_VALIDATE_SMS}
								component={ValidateSessionByCodeScreen}
							/>

							{/* Quiz Routes */}
							<PrivateRoute exact path={routes.COURSE_QUIZ} component={QuizScreen} />
							<PrivateRoute exact path={routes.MODULE_COURSE_QUIZ} component={QuizScreen} />
							<PrivateRoute exact path={routes.COURSE_SURVEY} component={QuizScreen} />
							<PrivateRoute exact path={routes.FOLDER_SURVEY} component={QuizScreen} />
							<PrivateRoute exact path={routes.SESSION_SURVEY} component={QuizScreen} />
							<PrivateRoute exact path={routes.MODULE_COURSE_SURVEY} component={QuizScreen} />
							<PrivateRoute exact path={routes.MODULE_SESSION_SURVEY} component={QuizScreen} />
							<PrivateRoute exact path={routes.MODULE_FOLDER_SURVEY} component={QuizScreen} />

							<PrivateRoute
								exact
								path={routes.COURSE_QUIZ_QUESTION}
								component={QuestionScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_COURSE_QUIZ_QUESTION}
								component={QuestionScreen}
							/>
							<PrivateRoute
								exact
								path={routes.COURSE_SURVEY_QUESTIONS}
								component={SurveyQuestionScreen}
							/>
							<PrivateRoute
								exact
								path={routes.FOLDER_SURVEY_QUESTIONS}
								component={SurveyQuestionScreen}
							/>
							<PrivateRoute
								exact
								path={routes.SESSION_SURVEY_QUESTIONS}
								component={SurveyQuestionScreen}
							/>

							<PrivateRoute
								exact
								path={routes.MODULE_COURSE_SURVEY_QUESTIONS}
								component={SurveyQuestionScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_FOLDER_SURVEY_QUESTIONS}
								component={SurveyQuestionScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_SESSION_SURVEY_QUESTIONS}
								component={SurveyQuestionScreen}
							/>

							<PrivateRoute
								exact
								path={routes.COURSE_QUIZ_SUBMIT}
								component={SubmitQuizScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_COURSE_QUIZ_SUBMIT}
								component={SubmitQuizScreen}
							/>

							<PrivateRoute
								exact
								path={routes.COURSE_SURVEY_SUBMIT}
								component={SubmitQuizScreen}
							/>
							<PrivateRoute
								exact
								path={routes.FOLDER_SURVEY_SUBMIT}
								component={SubmitQuizScreen}
							/>
							<PrivateRoute
								exact
								path={routes.SESSION_SURVEY_SUBMIT}
								component={SubmitQuizScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_COURSE_SURVEY_SUBMIT}
								component={SubmitQuizScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_FOLDER_SURVEY_SUBMIT}
								component={SubmitQuizScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_SESSION_SURVEY_SUBMIT}
								component={SubmitQuizScreen}
							/>

							<PrivateRoute exact path={routes.FOLDER_QUIZ} component={QuizScreen} />
							<PrivateRoute exact path={routes.MODULE_FOLDER_QUIZ} component={QuizScreen} />

							<PrivateRoute
								exact
								path={routes.FOLDER_QUIZ_QUESTION}
								component={QuestionScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_FOLDER_QUIZ_QUESTION}
								component={QuestionScreen}
							/>

							<PrivateRoute
								exact
								path={routes.FOLDER_QUIZ_SUBMIT}
								component={SubmitQuizScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_FOLDER_QUIZ_SUBMIT}
								component={SubmitQuizScreen}
							/>

							<PrivateRoute exact path={routes.SESSION_QUIZ} component={QuizScreen} />
							<PrivateRoute exact path={routes.MODULE_SESSION_QUIZ} component={QuizScreen} />

							<PrivateRoute
								exact
								path={routes.SESSION_QUIZ_QUESTION}
								component={QuestionScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_SESSION_QUIZ_QUESTION}
								component={QuestionScreen}
							/>

							<PrivateRoute
								exact
								path={routes.SESSION_QUIZ_SUBMIT}
								component={SubmitQuizScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_SESSION_QUIZ_SUBMIT}
								component={SubmitQuizScreen}
							/>

							<PrivateRoute
								exact
								path={routes.SESSION_DISCUSSION}
								component={DiscussionScreen}
							/>
							<PrivateRoute
								exact
								path={routes.MODULE_SESSION_DISCUSSION}
								component={DiscussionScreen}
							/>
							<PrivateRoute
								exact
								path={routes.QUIZ_REVIEW_DETAIL}
								component={QuizReviewDetailScreen}
							/>

							<Route component={NotFound} />
						</Switch>
					</CssBaseline>
				</HashRouter>
			</MuiThemeProvider>
		);
	}
}

const mapStateToProps = ({ currentUser: { institution } }: Store) => ({
	institution
});

export default connect(mapStateToProps)(App);
