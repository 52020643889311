import { ReactElement } from 'react';
import { FileData } from '../components/FileUploadWidget';

export interface ModuleModel {
	hashID: string;
	name: string;
	description: string;
	courseHashIDs: Course['hashID'][];
}

interface QuestionAnswerModel {
	id: QuestionAnswer['id'];
	title: QuestionAnswer['title'];
	correct: QuestionAnswer['correct'];
	feedback: QuestionAnswer['feedback'];
	__typename: 'QuestionAnswer';
}

interface QuestionModel {
	hashID: Question['hashID'];
	title: Question['title'];
	type: Question['type'];
	answers: QuestionAnswerModel[];
}

interface QuizModel {
	hashID: Quiz['hashID'];
	title: Quiz['title'];
	type: Quiz['type'];
	description: Quiz['description'];
	questions: QuestionModel[];
	__typename: 'Quiz';
}

export interface QuestionResponseModel {
	questionHashID: Question['hashID'];
	text: null | string;
	correct: boolean | null;
	answerID: number;
	__typename: 'QuestionResponse';
}

export interface QuizResultModel {
	id: string;
	grade: null | string;
	totalCorrect: null | number;
	__typename: 'QuizResult';
	quiz: QuizModel;
	responses: QuestionResponseModel[];
}

export interface Store {
	network: Network;
	currentUser: User;
	content: Content;
	updates: Updates;
	settings: Settings;
	logout: Logout;
}

export interface User {
	readonly __typename?: 'User';
	analyticsToken: string | null;
	loginState: string | null;
	token: string | null;
	tokenIssuedAt: string | null;
	firstName: string | null;
	lastName?: string | null;
	country: string | null;
	phoneNumber: string | null;
	email: string | null;
	locale: Locale | null;
	message: string | null;
	isLoggingIn: boolean | null;
	firstLogin: boolean | null;
	viewedTutorials: {
		modules: boolean;
		moduleCourses: boolean;
		courses: boolean;
		courseInfo: boolean;
		sessions: boolean;
		sessionDetails: boolean;
		bookmarks: boolean;
		help: boolean;
		quiz: boolean;
		quizQuestion: boolean;
	};
	institution: Institution;
}

export interface Institution {
	readonly __typename?: 'Institution';
	id: number | null;
	name: string | null;
	image: File | null;
	color: string | null;
	about: string | null;
	analyticsCode: string | null;
	isMerchant: boolean | null;
}

export interface Network {
	isOnline: boolean;
}

export interface Updates {
	isFetching: boolean;
	isErrorFetching: boolean;
	hasUpdates: boolean;
	dateLastCheck: Date | string | null;
	size: number;
}

export interface Settings {
	locale: Locale;
	consent: boolean;
}

export interface Logout {
	loggingOut: boolean;
	isForced: boolean;
}

export interface Content {
	isFetching: boolean;
	isErrorFetching: boolean;
	isSessionValidating: boolean;
	isSessionFailValidating: boolean;
	isSessionValidated: boolean;
	isQuizSubmitting: boolean;
	isQuizFailSubmitting: boolean;
	isQuizSubmitted: boolean;
	isFetchingBookmarks: boolean;
	isQuizResultFetching: boolean;
	isQuizResultErrorFetching: boolean;
	isCourseCompleteDialogOpen: boolean;
	courseCompletedHashId: string | null;
	completedCoursesWithCongratulations: string[];
	updatedAt: Date | string | null;
	modules: { [hashID: string]: Module };
	courses: { [hashID: string]: Course };
	folders: { [hashID: string]: Folder };
	sessions: { [hashID: string]: Session };
	quizzes: { [hashID: string]: Quiz };
	bookmarks: { [url: string]: Bookmark };
	quizResults: { [hashID: string]: QuizResult };
	toggled: {
		folders: Array<Folder['hashID']>;
	};
	viewed: {
		sessions: Array<Session['hashID']>;
		folders: Array<Folder['hashID']>;
	};
	spool: {
		texts: Array<Session['hashID']>;
		files: Array<File['url']>;
		removedBookmarks: string[];
		total: number;
		isFetching: boolean;
	};
	spoolErrors: {
		files: Array<File['url']>;
		total: number;
	};
}

export interface Module {
	readonly __typename?: 'Module';
	hashID: string;
	name: string;
	description: string | null;
	courses: Array<Course['hashID']>;
}

export interface Course extends Common, Quizzable {
	readonly __typename?: 'Course';
	image: File | null;
	teacher: string;
	description: string | null;
}

export interface Folder extends Common, Quizzable {
	readonly __typename?: 'Folder';
	isDefault: boolean;
	courseHashID: Course['hashID'];
}

export interface Session extends Common, Quizzable {
	contentType: string;
	readonly __typename?: 'Session';
	index: number;
	contentUpdatedAt: Date | string;
	progressionLock: boolean;
	progressed: boolean;
	discussable: boolean;
	unblockCode: string | null;
	progressCode: string | null;
	files: { [index: string]: File };
	courseHashID: Course['hashID'];
	folderHashID: Folder['hashID'];
	content: string | null;
	spoolComplete?: boolean;
}

export type UserResponse = string | string[];

export interface Quiz extends Exclude<Common, 'updatedAt'> {
	readonly __typename?: 'Quiz';
	type: QuizTypeEnum;
	description: string | null;
	questions: Array<Question> | null;
	userResponses: { [index: string]: UserResponse };
	files?: { [index: string]: FileData[] };
	submitted?: boolean;
}

export interface Question extends Exclude<Common, 'updatedAt'> {
	readonly __typename?: 'Question';
	preamble: string | null;
	hint: string | null;
	required: boolean;
	image: File | null;
	type: QuestionTypeEnum;
	feedback: string | null;
	answers: Array<QuestionAnswer>;
	correctAnswers?: number[];
	isMultiple?: boolean;
	subtitle?: string;
	uploadFileAnswer: {
		isRequired?: boolean;
	} | null;
}

export interface QuestionAnswer {
	readonly __typename?: 'QuestionAnswer';
	id: number;
	title: string;
	correct: boolean | null;
	feedback: string | null;
}

export interface QuizResult {
	readonly __typename?: 'QuizResult';
	id: number;
	quiz: Quiz;
	grade: number | null;
	totalCorrect: number | null;
	responses: Array<QuestionResponse> | null;
}

export interface QuestionResponse {
	readonly __typename?: 'QuestionResponse';
	questionHashID: string;
	text: string | null;
	correct: boolean | null;
	answerID: QuestionAnswer['id'] | null;
}

export interface Bookmark {
	readonly __typename?: 'Bookmark';
	title: string;
	createdAt: Date | string;
}

export interface Action {
	type: string;
	payload?: any;
}

/** Shared interfaces **/
export interface File {
	readonly __typename?: 'File';
	url: string;
	size: number;
	createdAt?: Date | string;
	updatedAt?: Date | string;
}

interface Quizzable {
	quizHashID?: Quiz['hashID'];
}

export interface Common {
	hashID: string;
	title: string;
	updatedAt: Date | string;
}

export type Locale = string;

/** Enums **/
export enum QuestionTypeEnum {
	checkbox = 'checkbox',
	essay = 'essay',
	multiple_choice = 'multiple_choice',
	numerical_range = 'numerical_range',
	rating_scale = 'rating_scale',
	sequence = 'sequence',
	short_exact = 'short_exact',
	mcq = 'mcq',
	oeq = 'oeq'
}

export enum QuizTypeEnum {
	graded = 'graded',
	self_assessment = 'self_assessment',
	survey = 'survey'
}

export enum Category {
	modules = 'modules',
	courses = 'courses',
	folders = 'folders',
	sessions = 'sessions',
	content = 'content'
}

export interface SearchResult {
	title: string;
	url: string;
	hashID: string;
	content?: string;
	icon?: ReactElement;
	image?: string;
}

export interface Progression {
	sessions: string[];
	folders: string[];
}
