import { defaultLocale } from '../../config/translations';

export const LOGIN_STATE_LOGGED_IN = 'logged-in';
export const LOGIN_STATE_LOGGED_OUT = 'logged-out';

const initialState = {
	network: {
		isOnline: true
	},
	currentUser: {
		loginState: LOGIN_STATE_LOGGED_OUT,
		token: null,
		tokenIssuedAt: null,
		firstName: null,
		lastName: null,
		country: null,
		phoneNumber: null,
		analyticsToken: null,
		email: null,
		locale: null,
		message: null,
		isLoggingIn: false,
		firstLogin: null,
		viewedTutorials: {
			modules: true,
			moduleCourses: true,
			courses: true,
			courseInfo: true,
			sessions: true,
			sessionDetails: true,
			bookmarks: true,
			help: true,
			quiz: true,
			quizQuestion: true
		},
		institution: {
			id: null,
			name: null,
			color: process.env.REACT_APP_DEFAULT_APP_COLOR || null,
			about: null,
			analyticsCode: process.env.REACT_APP_GOOGLE_ANALYTICS_CODE || null,
			image: null,
			isMerchant: null
		}
	},
	content: {
		isFetching: false,
		isErrorFetching: false,
		isSessionValidating: false,
		isSessionFailValidating: false,
		isSessionValidated: false,
		isQuizSubmitting: false,
		isQuizFailSubmitting: false,
		isQuizSubmitted: false,
		isFetchingBookmarks: false,
		isQuizResultFetching: false,
		isQuizResultErrorFetching: false,
		isCourseCompleteDialogOpen: false,
		courseCompletedHashId: null,
		completedCoursesWithCongratulations: [],
		updatedAt: null,
		modules: {},
		courses: {},
		folders: {},
		sessions: {},
		quizzes: {},
		bookmarks: {},
		quizResults: {},
		toggled: {
			folders: []
		},
		viewed: {
			sessions: [],
			folders: []
		},
		spool: {
			texts: [],
			files: [],
			removedBookmarks: [],
			total: 0,
			isFetching: false
		},
		spoolErrors: {
			files: [],
			total: 0
		}
	},
	updates: {
		isFetching: false,
		isErrorFetching: false,
		hasUpdates: false,
		dateLastCheck: null,
		size: 0
	},
	settings: {
		locale: defaultLocale,
		consent: false
	},
	logout: {
		loggingOut: false,
		isForced: false
	}
};

export default initialState;
