import React from 'react';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import I18n from 'i18n-js';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { hideCourseCompleteDialog } from '../../redux/actions/actionCreators';
import { Store } from '../../types';

const CourseCompleteDialog = () => {
	const dispatch = useDispatch();
	const locale = useSelector((state: Store) => state.currentUser.locale);
	const isCourseCompleteDialogOpen = useSelector(
		(state: Store) => state.content.isCourseCompleteDialogOpen
	);
	const courseCompletedHashId = useSelector((state: Store) => state.content.courseCompletedHashId);
	const courses = useSelector((state: Store) => state.content.courses);

	const courseCompletedName = courseCompletedHashId ? courses[courseCompletedHashId].title : '';

	const handleClose = () => {
		dispatch(hideCourseCompleteDialog());
	};

	return (
		<Dialog open={isCourseCompleteDialogOpen} data-test-id="course_complete-dialog_container">
			<DialogContent>
				<img
					src={require('../../assets/congratulations-star.png')}
					style={{
						height: '110px',
						objectFit: 'contain',
						margin: '2.5em 0em',
						marginBottom: '1em'
					}}
				/>
				<Typography
					variant="h6"
					style={{ textAlign: 'center', marginTop: '0.5em', marginBottom: '1em' }}
				>
					{I18n.t('course.congratulations', { locale })}!
				</Typography>
				<DialogContentText
					data-test-id="course-complete-dialog_info"
					style={{ marginBottom: '1em', textAlign: 'center' }}
					dangerouslySetInnerHTML={{
						__html: I18n.t('course.sessionsCompleted', {
							locale,
							courseName: courseCompletedName
						})
					}}
				></DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button
					data-test-id="course-complete-dialog_dismiss-btn"
					color="primary"
					onClick={handleClose}
					fullWidth
					style={{ marginBottom: '1em' }}
				>
					{I18n.t('course.great', { locale })}!
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CourseCompleteDialog;
