/**
 * List of migrations in YYYYMMDD format
 * This is a list of changes to make to the state being rehydrated.
 * The keys must be integers, and migrations will be performed in ascending key order.
 */
import { Content, Store } from '../../types';
import { MigrationManifest } from 'redux-persist';

const migrations: MigrationManifest = {
	/**
	 * 12th Nov 2018
	 * Renamed sessionText to texts
	 * Renamed sessionFiles to files
	 */
	20181112: (state: any) => ({
		...state,
		content: {
			...state.content,
			spool: {
				...state.content.spool,
				texts: [...state.content.spool.sessionText],
				files: [...state.content.spool.sessionFiles],
				sessionText: undefined,
				sessionFiles: undefined
			},
			spoolErrors: {
				...state.content.spoolErrors,
				files: [...state.content.spoolErrors.sessionFiles],
				sessionFiles: undefined
			}
		}
	}),
	/**
	 * 21st Jan 2019
	 * Added toggled state to content
	 */
	20190121: (state: any) => ({
		...state,
		content: {
			...state.content,
			toggled: {
				folders: []
			}
		}
	}),
	/**
	 * 25th Jan 2019
	 * Added modules to content
	 */
	20190125: (state: any) => ({
		...state,
		content: {
			...state.content,
			modules: {}
		}
	}),
	/**
	 * 1st Feb 2019
	 * Added viewedTutorials to currentUser
	 */
	20190201: (state: any) => ({
		...state,
		currentUser: {
			...state.currentUser,
			viewedTutorials: {
				modules: true,
				moduleCourses: true,
				courses: true,
				courseInfo: true,
				sessions: true,
				sessionDetails: true,
				bookmarks: true,
				help: true,
				quiz: true,
				quizQuestion: true
			}
		}
	}),

	/**
	 * 2nd April 2019
	 * Renamed questionAnswers to answers
	 */
	20190402: (state: any) => {
		let quizzes = state.content.quizzes;
		let newQuizzes = {};

		Object.keys(quizzes).forEach((quizHashID) => {
			let questions = quizzes[quizHashID].questions;

			let newQuestions = questions!.map((question: any) => {
				if (question.questionAnswers) {
					question.answers = question.questionAnswers;
					question.questionAnswers = undefined;
				}
				return question;
			});

			newQuizzes = {
				...newQuizzes,
				[quizHashID]: {
					...quizzes[quizHashID],
					questions: newQuestions
				}
			};
		});

		return {
			...state,
			content: {
				...state.content,
				quizzes: newQuizzes
			}
		};
	},

	/**
	 * 29th July 2019
	 * Renamed compoound_multiple_choice to checkbox
	 */
	20190729: (state: any) => {
		let quizzes = state.content.quizzes;
		let newQuizzes = {};

		Object.keys(quizzes).forEach((quizHashID) => {
			let questions = quizzes[quizHashID].questions;

			let newQuestions = questions!.map((question: any) => {
				if (question.type === 'compound_multiple_choice') {
					question.type = 'checkbox';
				}
				return question;
			});

			newQuizzes = {
				...newQuizzes,
				[quizHashID]: {
					...quizzes[quizHashID],
					questions: newQuestions
				}
			};
		});

		return {
			...state,
			content: {
				...state.content,
				quizzes: newQuizzes
			}
		};
	},
	/**
	 * 30th October 2019
	 * Added feedback to question answers
	 */
	20191030: (state: any) => {
		let quizzes = state.content.quizzes;
		let newQuizzes = {};

		Object.keys(quizzes).forEach((quizHashID) => {
			let questions = quizzes[quizHashID].questions;

			let newQuestions = questions!.map((question: any) => {
				if (question!.answers) {
					question!.answers.map((answer: any) => {
						if (!answer!.feedback) {
							answer!.feedback = null;
						}
						return answer;
					});
				}

				return question;
			});

			newQuizzes = {
				...newQuizzes,
				[quizHashID]: {
					...quizzes[quizHashID],
					questions: newQuestions
				}
			};
		});

		return {
			...state,
			content: {
				...state.content,
				quizzes: newQuizzes
			}
		};
	},
	/**
	 * 10th January 2020
	 * Renamed is_required to required
	 */
	20200110: (state: any) => {
		let quizzes = state.content.quizzes;
		let newQuizzes = {};

		Object.keys(quizzes).forEach((quizHashID) => {
			let questions = quizzes[quizHashID].questions;

			let newQuestions = questions!.map((question: any) => {
				if (question!.is_required !== undefined) {
					question!.required = question.is_required;
					question!.is_required = undefined;
				}
				return question;
			});

			newQuizzes = {
				...newQuizzes,
				[quizHashID]: {
					...quizzes[quizHashID],
					questions: newQuestions
				}
			};
		});

		return {
			...state,
			content: {
				...state.content,
				quizzes: newQuizzes
			}
		};
	},
	/**
	 * 10th May 2020
	 * Added isLoggingIn and message to currentUser
	 */
	20200510: (state: any) => ({
		...state,
		currentUser: {
			...state.currentUser,
			isLoggingIn: false,
			message: null
		}
	}),

	/**
	 * 26th April 2021
	 * Added isFetching to content.spool
	 */
	20210426: (state: any) => ({
		...state,
		content: {
			...state.content,
			spool: {
				...state.content.spool,
				isFetching: false
			}
		}
	}),

	/**
	 * 6th April 2023
	 */
	20230406: (state: any) => ({
		...state,
		currentUser: {
			...state.currentUser,
			analyticsToken: null
		}
	}),

	/**
	 * 1st June 2023
	 */
	20230601: (state: any) => ({
		...state,
		currentUser: {
			...state.currentUser,
			email: null
		}
	}),

	/*
	 * 11th September 2023
	 */
	//@ts-ignore
	20230911: (state: Store) => {
		const currentSessionHashes = Object.keys(state.content.sessions);
		const sessionsWithNewProperty: Content['sessions'] = {};
		currentSessionHashes.forEach((hashID) => {
			sessionsWithNewProperty[hashID] = {
				...state.content.sessions[hashID],
				spoolComplete: true
			};
		});
		return {
			...state,
			content: {
				...state.content,
				sessions: sessionsWithNewProperty
			}
		};
	},
	/*1
	 * 12th June 2024
	 */
	//@ts-ignore
	20240612: (state: Store) => {
		return {
			...state,
			content: {
				...state.content,
				isQuizResultFetching: false,
				isQuizResultErrorFetching: false
			}
		};
	},
	/*
	 * 2nd Dec 2024
	 */
	//@ts-ignore
	20241204: (state: Store) => {
		return {
			...state,
			content: {
				...state.content,
				courseCompletedHashId: null,
				isCourseCompleteDialogOpen: false,
				completedCoursesWithCongratulations: []
			}
		};
	}
};

export default migrations;
