export default () => ({
	course: {
		noContentAvailable: 'ไม่มีเนื้อหา',
		view: 'ดู',
		sessionsCompleted: 'คุณสำเร็จทุกบทเรียนในหลักสูตร %{courseName} เรียบร้อยแล้ว',
		congratulations: 'ขอแสดงความยินดี',
		great: 'ยอดเยี่ยม'
	},
	session: {
		nextButton: 'ต่อไป',
		previous: 'ก่อนหน้านี้',
		quizButton: 'ที่หน้าตำถาม',
		surveyButton: 'ไปที่หน้าเซอร์เวย์',
		end: 'เสร็จสิ้น'
	},
	question: {
		next: 'ต่อไป',
		previous: 'ก่อนหน้านี้',
		saveAndNext: 'เซฟและไปต่อ',
		answer: 'คำตอบ',
		checkAnswer: 'ตรวจคำตอบ',
		numericalRangeFeedback:
			'คำตอบที่ถูกต้องคือหมายเลขระหว่าง <b>%{lowerNumber}</b> และ <b>%{higherNumber}</b>',
		answerAll: 'ยินดีตอบคำถาม <b>ที่ต้องตอบ</b> ทั้งหมดก่อนดำเนินการต่อ',
		uploadAll: 'กรุณาอัปโหลดไฟล์ทั้งหมดที่ <b>ต้องการ</b> ก่อนทำต่อไป',
		maxFiles: 'คุณสามารถอัปโหลดได้สูงสุด {number} วิดีโอเท่านั้น',
		confirmRemove: 'คุณแน่ใจหรือว่าต้องการลบไฟล์',
		confirm: 'ยืนยัน',
		backToStart: 'กลับไปที่จุดเริ่มต้น',
		attempt: 'ยินดีพยายามตอบคำถามนี้ก่อนดำเนินการต่อ',
		question: 'คำถาม',
		of: 'ของ',
		end: 'เสร็จสิ้น',
		hint: 'กดเพื่อดูคำใบ้',
		helperText: {
			essay: 'ใส่คำตอบข้างล่างนี้',
			multipleChoice: 'เลือกคำตอบหนึ่งข้อ',
			compoundMultipleChoice: 'เลือกคำตอบที่ เหมาะสมทั้งหมด',
			numericalRange: 'ใส่หมายเลขในขอบเขตของคำตอบที่เหมาะสม',
			shortExact: 'ใส่คำตอบข้างล่างนี้',
			sequence: 'ลากและปล่อยวัตถุเพื่อจัดวางพวกมันให้อยู่ในบำดับที่เหมาะสม',
			ratingScale: 'ลากสไลเดอร์เพื่อเลือกและตอบ'
		}
	},
	feedback: {
		good: 'ดีมาก!',
		bad: 'ผิด'
	},
	tokenError: {
		'401': 'ลิงก์ของคุณมีปัญหา - โปรดมติดต่อ Chalkboard Education เพื่อขอความช่วยเหลือ',
		'402':
			'เหรียญของคุณถูกใช้งานอยู่บนอุปกรณ์อื่น การลงชื่อเข้าหลายอุปกรณ์เป็นข้อต้องห้ามของสถาบันของคุณ โปรดติดต่อ Chalkboard Education ถ้าคุณเชื่อว่านี้เป็นข้อผิดพลาด'
	},
	discussion: {
		title: 'การสนทนา',
		messagePlaceholder: 'ป้อนข้อความของคุณ'
	},
	update: {
		label: 'ดาวน์โหลด',
		checkForUpdates: 'ตรวจสอบอัพเดท',
		needUpdate: 'มีเนื้อหาให้อัพเดท...',
		updating: 'กำลังเรียกเนื้อหา...',
		checking: 'ตรวจหาเนื้อหา...',
		fetchingQuizResults: 'กำลังดึงผลการทดสอบ...',
		downloadSize: '{{size}} {{type}}',
		toDownload: 'เพื่อดาวน์โหลด',
		KB: 'กิโลไบต์',
		MB: 'เมกะไบต์',
		upToDate: 'เนื้อหาที่มีทั้งหมดถูกดาวน์โหลดแล้ว!',
		errorWhileCheckingUpdates: 'มีปัญหาเครือข่ายขณะที่กำลังตรวจหาการอัพเดท',
		errorWhileUpdating: 'ปัญหาเครือข่ายเกิดขึ้นในขณะที่กำลังดาวน์โหลดเนื้อหา',
		stayOnline: 'โปรดรักษาสถานะออนไลน์และเปิดหน้านี้เอาไว้ กระบวนการนี้อาจใช้เวลาหลายนาที',
		offline: 'คุณออฟไลน์อยู่',
		success: 'ดาวน์โหลดเนื้อหาสำเร็จ!',
		warning: 'เนื้อหาถูกดาวน์โหลดแล้วส่วนหนึ่ง',
		retry: 'ลองอีกครั้ง',
		retryInFewMinutes: 'กรุณาลองอีกครั้งในไม่กี่นาที',
		connectAndRetry: 'โปรดตรวจสอบว่าคุณเชื่อมต่อกับอินเทอร์เน็ตแล้วลองใหม่',
		connectAndRetryInMinutes: 'โปรดตรวจสอบว่าคุณเชื่อมต่อกับอินเทอร์เน็ตและลองอีกครั้งในไม่กี่นาที',
		downloadInProgress: 'กำลังดาวน์โหลด',
		contentWillAppear: 'คุณจะเห็นเนื้อหาเมื่อดาวน์โหลดเสร็จสิ้น',
		edit: 'แก้ไข',
		downloadingContent: 'กำลังดาวน์โหลดเนื้อหา',
		missing: 'ดาวน์โหลดสื่อที่หายไป',
		dismiss: 'ยกเลิก',
		contentLoading: 'กำลังดาวน์โหลดเนื้อหา คุณสามารถดูสถานะโดยแตะที่วงกลมที่มุมล่างขวาของหน้าจอของคุณ'
	},
	send: {
		label: {
			quiz: 'ส่งความคืบหน้าของคุณด้วย:',
			survey:
				"งานดีมากในการสำเร็จการสำรวจ! เพื่อให้แน่ใจในความถูกต้อง โปรดตรวจทานคำตอบของคุณอีกครั้ง หากคุณแน่ใจเรื่องคำตอบของคุณแล้ว โปรดดำเนินการโดยคลิกที่ปุ่ม 'ส่ง'"
		},
		sessionListButton: `รายการเซสชั่น`,
		nextButton: 'ต่อไป',
		medium: {
			internet: 'อินเตอร์เน็ต',
			sms: 'SMS'
		},
		validation: {
			success: 'ขอบคุณ ความคืบหน้าของคุณถูกเซฟแล้ว',
			fail: `เกิดข้อผิดพลาดขณะตรวจสอบความถูกต้องของเซสชั่น`
		},
		sms: {
			title: 'ส่งความคืบหน้าของคุณโดย SMS',
			label: 'คุณจะส่งรหัสโดย SMS และได้รับรหัสความคืบหน้ากลับไป ห้ามแก้ไขรหัสใดๆ ขอบคุณ',
			button: 'ส่งโดย SMS',
			notworking: {
				label: 'ส่ง SMS ด้วยตนเอง...',
				button: 'ไม่ทำงานหรือ?',
				toPhone: 'หมายเลขโทรศัพท์',
				unblockCode: 'รหัสสำหรับส่ง'
			},
			progressCode: {
				button: 'ใส่รหัสความคืบหน้า',
				placeholder: 'รหัสความคืบหน้า',
				title: 'ใส่รหัสที่คุณได้รับกลับไปจาก SMS ข้างล่างนี้:',
				cancel: 'ยกเลิก',
				submit: 'ส่ง'
			}
		}
	},
	login: {
		checking: 'กำลังตรวจสอบ ...',
		start: 'เริ่ม',
		title: 'ลงชื่อเข้า',
		message:
			'คุณสามารถลงชื่อเข้าด้วยลิงก์ SMS ที่มีให้ หรืออีกทางเลือกหนึ่ง คุณสามารถใส่เหรียญลงชื่อเข้าของคุณข้างล่างนี้',
		hint:
			'คุณสามารถใช้ลิงก์ลงชื่อเข้าที่ส่งถึงคุณผ่าน SMS เพื่อลงชื่อเข้ากลับเข้ามา<br />อีกทางเลือกหนึ่ง เหรียญ 6 ตัวอักษรของคุณสามารถถูกพบต่อท้าย URL ลงชื่อเข้าที่ถูกส่งให้คุณผ่าน SMS ยกตัวอย่างเช่น: https://chalkedu.co/#/login/<b>token</b>',
		tokenPlaceholder: 'เหรียญ'
	},
	account: {
		progress: {
			title: `ความคืบหน้า`,
			subtitle: `%{read}/%{remaining} เซสชันอ่าน`
		},
		language: 'ภาษา',
		settings: {
			label: 'การตั้งค่า'
		},
		about: 'เกี่ยวกับ',
		consent: 'ยินยอม',
		support: {
			title: 'สายช่วยเหลือ',
			subtitle: 'คุณสามารถติดต่อเราผ่านวิธีเหล่านี้เพื่อขอความช่วยเหลือเพิ่มเติม'
		}
	},
	error: {
		label: 'อุปส์... เหมือนว่าจะมีบางอย่างผิดพลาด',
		report: 'รายงานข้อผิดพลาด',
		back: 'กลับไปที่หน้าหลัก',
		dismiss: 'ยกเลิก',
		authenticationError: 'ข้อผิดพลาดในการตรวจสอบสิทธิ์',
		spoolError: {
			title: 'ดาวน์โหลดไม่สมบูรณ์',
			description:
				'บางไฟล์ล้มเหลวที่จะดาวน์โหลด อย่าได้กังวล เราจะพยายามดาวน์โหลดพวกมันใหม่ในการอัพเดทครั้งต่อไป'
		}
	},
	'404': {
		title: '404 ไม่พบหน้านี้',
		description: 'เราขอโทษแต่หน้าที่คุณกำลังหาไม่มีอยู่จริง',
		back: 'ย้อนกลับ'
	},
	logout: {
		title: 'ยืนยันการล็อคเอาท์',
		message: 'คุณแน่ใจใช่ไหมว่าคุณต้องการลงชื่อออก?',
		button: 'ลงชื่อออก',
		cancelButton: 'ยกเลิก'
	},
	selfTestQuiz: {
		okay: 'โอเค',
		checkAnswer: 'ตรวจคำตอบ',
		answers: 'คำตอบ',
		essayResponse: 'ขอบคุณสำหรับการตอบสนอง!'
	},
	quiz: {
		label: 'คุณต้องทำควิซดังต่อไปนี้ให้เสร็จก่อนที่คุณจะดำเนินการต่อ!',
		button: {
			begin: 'เริ่มต้น',
			skip: 'ข้าม'
		},
		upload: 'เลือกไฟล์เพื่ออัปโหลด',
		uploadedFiles: 'ไฟล์ที่อัปโหลดแล้ว',
		tapToUpload: 'แตะไอคอนเพื่อเลือกไฟล์ที่ต้องการอัปโหลด',
		commence: 'คุณกำลังจะทำแบบทดสอบสำหรับเซสชันนี้',
		submit: {
			submitting: 'กำลังส่ง',
			stayOnline: 'คุณแน่ใจหรือว่าต้องการส่งคำตอบของคุณ?\nโปรดตรวจสอบว่าคุณออนไลน์และเก็บหน้าจอเปิดไว้',
			inProgress: 'กำลังดำเนินการส่ง อาจใช้เวลาถึงหนึ่งนาที',
			success: 'ส่งควิซสำเร็จ!',
			error: 'ผิดพลาดในการส่งควิซ! โปรดลองใหม่อีกครั้งในภายหลัง',
			offline: 'ไม่สามารถส่งคำตอบเมื่อออฟไลน์'
		},
		continue: 'ไปต่อ',
		submitButton: 'ส่งแบบทดสอบ'
	},
	survey: {
		submitButton: 'ส่งเซอร์เวย์',
		submittedButton: 'ส่งเซอร์เวย์',
		commence: 'คุณกำลังจะตอบแบบสำรวจสำหรับเซสชันนี้',
		submit: {
			success: 'สำรวจได้รับการส่งเรียบร้อยแล้ว'
		}
	},
	badge: {
		quiz: 'ควิซ',
		survey: 'เซอร์เวย์',
		required: 'ที่จำเป็น'
	},
	menu: {
		modules: `โมดูล`,
		courses: `หลักสูตร`,
		account: 'บัญชี',
		bookmarks: 'การคั่นหน้า',
		quizReview: 'ตรวจสอบควิซ',
		help: 'ช่วยเหลือ',
		about: 'เกี่ยวกับ'
	},
	consent: {
		title: 'ฉันยินยอมให้ Chalkboard Education SAS รวบรวมและรักษา:',
		data: 'ข้อมูลความคืบหน้าและผลลัพธ์ของฉันจากแอพพริเคชั่นนี้',
		analytics: 'ข้อมูลความประพฤติของฉันอละข้อมูลส่วนบุคคลอื่นๆเพื่อการใช้งานแอพนี้'
	},
	custom: {
		moreInfo: 'ข้อมูลเพิ่มเติม',
		gotIt: 'รับทราบ',
		searchPlaceholder: 'เริ่มพิมพ์เพื่อค้นหา',
		noSearchResults: 'ไม่พบผลลัพธ์'
	},
	help: {
		title: 'ใช้งานแอพอย่างไร:',
		menu: {
			title: 'ค้นพบเมนู',
			subtitle: `กดที่ไอคอนเพื่อเปิดเมนู เมนูมีลิงก์ไปที่หลักสูตรและบัญชีของคุณ`
		},
		courses: {
			title: `หลักสูตร`,
			subtitle: `บนหน้าหลักสูตร คลิ๊กที่ <b>ดู</b> เพื่อเปิดหลักสูตร`
		},
		folders: {
			title: `แฟ้ม`,
			subtitle: `แต่ละหลักสูตรแบ่งออกเป็นแฟ้มต่างๆ`,
			description: `แต่ละแฟ้มถูกแบ่งออกเป็นเซสชั่นต่างๆ เซสชั่นมีเนื้อหาที่ผู้ใช้สามารถเรียนรู้`
		},
		sessions: {
			title: `เซสชั่น`,
			subtitle: `เซสชั่นมีเนื้อหาที่ผู้ใช้สามารถเรียนรู้`
		},
		navigatingSessions: {
			title: `เซสชั่นการนำทาง`,
			back: {
				subtitle: `เมื่ออยู่ในเซสชั่น คุณสามารถย้อนกลับไปที่รายการหลักด้วยลูกษรย้อนกลับ`
			},
			next: {
				subtitle: `หรือไปที่เซสชั่นถัดไปด้วยปุ่มที่ด้านล่างของหน้า:`
			}
		},
		bookmarks: {
			title: 'การคั่นหน้า',
			subtitle:
				'ใช้ไอคอนการคั่นหน้าเพื่อเซฟหน้าเพื่อเข้าถึงอย่างรวดเร็วจากส่วนการคั่นหน้าที่เมนูด้านข้าง'
		},
		quizzes: {
			title: 'ควิซ/ข้อสอบ',
			subtitle: `คุณอาจได้รับควิซในตอนต้นของหลักสูตร หรือแฟ้ม หรือในตอนจบของเซสชั่น`,
			description: `NB: เซสชั่นที่มีควิซถูกทำเครื่องหมายด้วยไอคอน:`
		},
		addToHomescreen: {
			title: 'เพิ่มไปที่หน้าหลัก',
			subtitle:
				'คุณสามารถติดตั้งแอพพลิเคชั่นนี้บนหน้าหลักของคุณเพื่อการเข้าถึงที่ง่ายและรวดเร็วเมื่อคุณต้องการ'
		},
		account: {
			title: 'บัญชี',
			subtitle: 'บนหน้าบัญชี คุณสามารถ:',
			updates: {
				description: 'ตรวจสอบการอัพเดทเนื้อหา'
			},
			language: {
				description: 'เปลี่ยนการตั้งค่าภาษาของคุณ'
			},
			logout: {
				description: 'ลงชื่ออกจากบัญชีของคุณ'
			},
			contact: {
				description: 'ติดต่อเราด้วยปัญหาใดๆที่คุณมีในการใช้แอพ'
			}
		},
		tutorial: 'เรียกใช้บทช่วยสอนแบบตอบโต้อีกครั้ง:'
	},
	onboarding: {
		'0': {
			title: 'บทเรียนตามความต้องการ',
			subtitle: 'วัสดุการเรียนรู้ทั้งหมดของคุณในที่เดียว... รวมไปถึงควิซเพื่อทดสอบความรู้ของคุณ'
		},
		'1': {
			title: 'ออฟไลน์ได้!',
			subtitle:
				'เมื่อเนื้อหาของคุณถูกดาวน์โหลดแล้ว มันใช้แบบออฟไลน์ได้ ดังนั้นคุณสามารถเข้าถึงมันเมื่อใดก็ได้'
		},
		'2': {
			title: 'เรียนได้ทุกที่ ทุกเวลา!',
			subtitle: 'เข้าถึงเนื้อหาของคุณจากอุปกร์ใดก็ได้ เพียงแค่ใช้ลิงก์ SMS ที่มีให้'
		},
		'3': {
			title: 'เข้าร่วมการเสวนา!',
			subtitle: `เข้าร่วมการเรียนรู้แบบเครือข่ายบนเซสชั่นที่สามารถแสดงความคิดเห็นกันได้`
		},
		'4': {
			title: 'เพิ่มไปที่หน้าหลักของคุณ!',
			subtitle:
				'ติดตั้งแอพพลิเคชั่นนี้ลงบนหน้าจอหลักของคุณเพื่อการเข้าถึงที่ง่ายและรวดเร็วเมื่อคุณพร้อม',
			instructions: {
				menu:
					'เพียงแค่กดปุ่ม <strong>เมนู</strong> ในเบราว์เซอร์ของคุณ<br />และเลือก <strong>เพิ่มไปที่หน้าหลัก</strong>',
				share:
					'เพียงแค่กดปุ่ม <strong>แชร์</strong> ในเบราว์เซอร์ของคุณ<br />และเลือก <strong>เพิ่มไปที่หน้าหลัก</strong>',
				clickButton: '...หรือโดยการกดที่ปุ่มข้างล่างนี้'
			}
		},
		addToHomescreen: 'เพิ่มไปที่หน้าหลัก',
		getStarted: 'เริ่มต้น',
		next: 'ต่อไป',
		skip: 'ข้าม'
	},
	'guided-tour': {
		title: 'บทช่วยสอบแบบตอบโต้',
		button: {
			finish: 'จบ',
			next: 'ต่อไป',
			previous: 'ก่อนหน้านี้',
			're-run': 'ทำซ้ำ'
		},
		modules: {
			'1': {
				title: `หน้าโมดูล`,
				content: `ยินดีต้อนรับสู่หน้า <b>โมดูล</b> <b>โมดูล</b> เป็นกลุ่ม <b>หลักสูตร</b> ที่คุณได้รับมอบหมายให้`
			},
			'2': {
				content: `การคลิ๊กบน <b>โมดูล</b> นำคุณไปยังรายการ <b>หลักสูตร</b> ทั้งหมดภายใต้ <b>โมดูล</b>`
			}
		},
		'module-courses': {
			'1': {
				content: `คุณอยู่บนหน้า <b>หลักสูตร</b> ของ <b>โมดูล</b> ที่คุณได้เลือก หลักสูตรภายใต้ <b>โมดูล</b> ถูกระบุไว้ที่นี่`
			}
		},
		courses: {
			'1': {
				title: `หน้าหลักสูตร`,
				content: `คุณอยู่บนหน้า <b>หลักสูตร</b> <b>หลักสูตร</b> ทั้งหมดของคุณถูกระบุอยู่ที่นี่`
			},
			'2': {
				content: 'เมื่อใดก็ตามที่คุณเห็นสิ่งนี้ หมายความว่ามีเนื้อหาใหม่ให้ดาวน์โหลด'
			},
			'3': {
				content: 'การคลิ๊กที่ <b>ดาวน์โหลด</b> เซฟเนื้อหาใหม่ลงบนอุปกรณ์ของคุณ'
			},
			'4': {
				content: `การคลิ๊กบนรูปภาพเผยให้เห็นคำอธิบายสั้นๆของ <b>หลักสูตร</b>`
			},
			'5': {
				content: `การคลิ๊กที่ <b>หลักสูตร</b> นำคุรไปที่หน้าข้อมูลของ <b>หลักสูตร</b> นั้นๆ`
			},
			'6': {
				content: 'คลิ๊กที่ <b>ตรวจหาอัพเดท</b> เพื่อตรวจหาเนื้อหาใหม่ๆ'
			}
		},
		'course-info': {
			'1': {
				content: `นี้คือ <b>หน้าหลักสูตร</b> ที่นี่คุณจะพบข้อมูลเพิ่มเติมเกี่ยวกับหลักสูตร รวมไปถึง <b>เซสชั่น</b> ที่ถูกจัดเข้า <b>แฟ้ม</b>`
			},
			'2': {
				content: `คุณสามารถพบคำอธิบายคร่าวๆเกี่ยวกับ <b>หลักสูตร</b> ได้ที่นี่`
			},
			'3': {
				content: `ข้างงล่างคำอธิบายหลักสูตรคือรายการของ <b>แฟ้ม</b>`
			},
			'4': {
				content: `การคลิ๊กที่ <b>แฟ้ม</b> เผยรายการของ <b>เซสชั่น</b> ภายใต้แฟ้มนั้นๆ`
			},
			'5': {
				content: `นี้คือรายการของ <b>เซสชั่น</b>`
			},
			'6': {
				content: `เพื่อเริ่มการอ่าน คลิ๊กลงบน <b>เซสชั่น</b>`
			}
		},
		'session-details': {
			'1': {
				content: `ยินดีต้อนรับเข้าสู่ <b>เซสชั่น</b> แรกของคุณ ที่นี่คือที่ๆคุณจะใช้เวลาส่วนใหญ่ของคุณ`
			},
			'2': {
				content: `เพื่อทำให้มันง่ายขึ้นในการกลับสู่ <b>เซสชั่น</b> คลิ๊กไปที่ไอคอน <b>คั่นหน้า</b> เพื่อสร้างลิงก์กลับมาที่ <b>เซสชั่น</b> นี้ <b>ที่คั่นหน้า</b> ที่คุณสร้างสามารถพบได้ใน <b>หน้าที่คั่นหน้า</b>`
			},
			'3': {
				content: 'การคลิ๊กที่ลูกษรขึ้นพาคุณไปยังหน้าก่อนหน้านี้'
			},
			'4': {
				content: `คลิ๊กที่ <b>ไอคอนคำอธิบาย</b> เพื่อดู <b>หน้าคำอธิบาย</b> ของ <b>เซสชั่น</b> ที่คุณกำลังดู`
			},
			'5': {
				content: `เพื่อไปยัง <b>เซสชั่น</b> ถัดไปใน <b>แฟ้ม</b> คลิ๊ก���ี่ <b>ปุ่มถัดไป</b>`
			},
			'6': {
				content: `เพื่อไปยัง <b>เซสชั่น</b> ก่อนหน้านี้ คลิ๊กที่ <b>ปุ่มก่อนหน้านี้</b>`
			},
			'7': {
				content: 'นี้คือ <b>ปุ่มเมนู</b> คลิ๊กที่มันบนหน้าใดก็ได้ เผย <b>เมนูด้านข้าง</b>'
			}
		},
		bookmarks: {
			'1': {
				content: 'หน้าทั้งหมดที่คุณคั่นหน้าเอาไว้ จะแสดงอญุ่ที่นี่!'
			},
			'2': {
				content: 'การคลิ๊กที่ <b>ที่คั่นหน้า</b> พาคุณไปยังหน้าที่คั่นเอาไว้อย่างรวดเร็ว'
			}
		},

		help: {
			'1': {
				content:
					'ยินดีต้อนรับสู่ <b>หน้าช่วยเหลือ</b> มาที่หน้านี้เมื่อไหร่ก็ตามทึคุณมีปัญหาในการเข้าใจการทำงานของแอพนี้'
			},
			'2': {
				content: 'คุรอาจไปยัง <b>หน้าช่วยเหลือ</b> เพื่อทบทวนคำแนะนำเมื่อไหร่ก็ได้...'
			},
			'3': {
				content: 'คลีกที่วัตถุใดก้ได้ในรายการเพื่อรับข้อมูลเพิ่มเติม...'
			},
			'4': {
				content: 'อีกทางเลือกหนึ่ง คุณสามารถติดต่อ Chalkboard Education ผ่านทางอีเมล'
			},
			'5': {
				content: 'หรือคุยกับเราโดยการโทรหาเราบนสายด่วยของเรา...'
			}
		},

		quiz: {
			'1':
				'ยินดีต้อนรับสู่ <b>หน้าควิซ</b> คุณจะเห็นหน้าจอคล้ายๆกันเมื่อใดก็ตามที่คุณกำลังจะพยายามทำควิซ',
			'2': 'นี่คือข้อมูลของควิซ คลิ๊กปุ่ม <b>เริ่มต้น</b> เพื่อเริ่ม...'
		},
		quizQuestion: {
			'1': 'สางนี้แสดงให้เห็นว่าคุณอยู่กับคำถามไหน และเหลือคำถามอีกกี่ข้อ...',
			'2': 'ตอบโต้และตอบคำถาม คลิ๊กปุ่ม <b>ถัดไป</b> ที่ด้านล่างของหน้าจอเพื่อไปต่อยังคำถามถัดไป',
			'3':
				'คำตอบของคุณถูกเซฟอัตโนมัติบนอุปกรณ์ของคุณเมื่อคุณกรอกมัน อย่าลังเลที่จะกลับมาที่มันในภายหลัง จนกว่าคุณจะส่งควิซถ้าเป็นไปได้!'
		}
	},
	routes: {
		login: 'ลงชื่อเข้า',
		welcome: 'ยินดีต้อนรับ',
		modules: `โมดูล`,
		courses: `หลักสูตร`,
		help: 'ช่วยเหลือ',
		about: 'เกี่ยวกับ',
		bookmarks: 'การคั่นหน้า',
		quizReview: 'ตรวจสอบควิซ',
		account: 'บัญชี',
		folders: `แฟ้ม`,
		sessions: `เซสชั่น`,
		quiz: 'ควิซ',
		question: 'คำถาม'
	},
	bookmarks: {
		none: 'ดูเหมือนว่าคุณไม่ม่ที่คั่นหน้าที่เซฟเอาไว้...',
		added: 'ที่คั่นหน้าถูกเพิ่ม',
		removed: 'ที่คั่นหน้าถูกลบ'
	},
	courses: {
		none: `หลักสูตรที่ถูกดาวน์โหลดจะโผล่ที่นี่...`,
		noPurchases: `คุณยังไม่ได้ทำการซื้อใดๆ`,
		purchaseMore: `คลิ๊กที่นี่เพื่อเพิ่มหลักสูตร`,
		purchaseCourses: `หลักสูตรที่ถูกซื้อ`
	},
	quizReview: {
		questionsTitle: {
			survey: 'คำถาม & ผลตอบรับ',
			graded: 'การล้มเหลว'
		},
		none: `คุณยังไม่ได้ทำควิซ <b>มีคะแนน</b> หรือ <b>เซอร์เวย์</b> ใดๆจนเสร็จ`,
		correct: 'ถูกต้อง',
		wrong: 'ผิด',
		notMarked: 'ยังไม่ได้ทำเครื่องหมาย',
		resultsIsFetching: 'กำลังดึงแบบทดสอบที่เคยทำไว้เพื่อการทบทวน...',
		errorFetchingResults: 'ขออภัยเกิดข้อผิดพลาดในการดึงผลการทดสอบ โปรดตรวจสอบการอัปเดตเพื่อลองอีกครั้ง'
	},
	folder: {
		quizLabel: `คุณต้องทำควิซนี้ให้เสร็จก่อนดูเนื้อหาของแฟ้มนี้:`,
		surveyLabel: `คุณต้องทำเซอร์เวย์ให้เสร็จก่อนดูเนื้อหาของแฟ้มนี้:`
	},
	modules: {
		none: `ไม่มีโมดูลที่ถูกดาวน์โหลด...`
	},
	register: {
		signUp: 'ลงชื่อเข้าใช้',
		button: 'ลงทะเบียน',
		registering: 'กำลังลงทะเบียน...',
		loginHint: 'มีบัญชีแล้วหรือยัง? ลงชื่อเข้าใช้',
		successful: 'ลงทะเบียนสำเร็จ',
		failed: 'ลงทะเบียนล้มเหลว ลองใหม่อีกครั้ง',
		label: {
			firstName: 'ชื่อจริง',
			lastName: 'นามสกุล',
			phoneNumber: 'หมายเลขโทรศัพท์',
			language: 'ภาษา'
		},
		hint: {
			firstName: 'ต้องใช้ชื่อจริง',
			lastName: 'ต้องใช้นามสกุล',
			phoneNumberLength: 'หมายเลขโทรศัพท์ควรยาวมากกว่า 9 หลัก',
			phoneNumberFormat: "หมายเลขโทรศัพท์ควรเริ่มด้วย '+'",
			verifyHuman: 'โปรดยืนยัน recaptcha เพื่อดำเนินการต่อ',
			language: 'ต้องใช้ภาษา'
		}
	},
	searchModal: {
		cancel: 'ยกเลิก',
		search: 'ค้นหา',
		searchIn: 'ค้นหาใน'
	},
	quizType: {
		survey: 'สำรวจ',
		graded: 'จัดอันดับ',
		selfAssessment: 'การประเมินตนเอง'
	}
});
